import React from 'react';

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero__wrapper container container--x">
                <h1>Cuidados De Saúde</h1>
                <p>A greatcare através desta unidade promove uma oferta diferenciada e integrada de serviços de saúde aos seus clientes, garantindo personalização e inovação tecnológica em proximidade e no conforto da sua casa.</p>
                <span>Temos ao dispor dos nossos clientes uma equipa multidisciplinar diferenciada, com formação e experiência nas seguintes valências:</span>
                <p>Enfermagem | Podologia | Análises clínicas<span className="number">1)</span> <span className="space"> | </span> Anatomia patológica<span className="number">1)</span> <span className="space"> | </span> Imagiologia<span className="number">2)</span></p>
            </div>
        </section>
    )
}

export default Hero
