import React from 'react';
// images
import img1 from '../../imgs/economiaSocial/img1.jpg';
import img2 from '../../imgs/economiaSocial/img2.jpg';
import img3 from '../../imgs/economiaSocial/img3.jpeg';
import img4 from '../../imgs/economiaSocial/img4.jpg';

const Hero = () => {
    return (
        <section className="hero-economia">
            <div className="hero-economia__wrapper">
                <div className="hero-economia-right">
                    <img src={img1} alt="" />
                    <div className="hero-economia-content container--x">
                        <div className="content-title">
                            <h2>Residência Assistida</h2>
                            <h3>Lar de Idosos</h3>
                        </div>
                        <div className="content-description">
                            <p>A greatcare dispõe, desde 2004, de um lar situado em Lisboa, na zona de Campolide.</p>
                            <p>​Esta unidade é composta por uma vivenda com 3 andares que abriu em 1999 e que foi totalmente remodelada em 2018, possuindo capacidade para 15 utentes.</p>
                            <p>Conta com acompanhamento diário e permanente por uma equipa multidisciplinar especializada em cuidados geriátricos (médicos, enfermeiros, fisioterapeutas, terapeutas da fala, psicólogos, ajudantes familiares, animadores sócioculturais, apoio religioso e outros).</p>
                            <p>Este Lar é uma resposta ao crescente envelhecimento da nossa população e, para tal, todo o trabalho vai ao encontro do superior interesse e bem-estar do idoso.</p>
                        </div>
                        <hr />
                        <div className="content-bottom">
                            <p>Bairro da Calçada dos Mestres - Rua 3, nº 1 - 1070-275 Lisboa</p>
                            <p>(Alvará n.º 48/2018)</p>
                            <p>info@greatecare.com</p>
                        </div>
                    </div>
                </div>


                <div className="hero-economia-left">
                    <img src={img2} alt="" className="small-img"/>
                    <div className="hero-economia-content container--x">
                        <div className="content-title">
                            <h2>Apoio Domiciliário</h2>
                        </div>
                        <div className="content-description">
                            <p>A greatcare vai lançar em breve um novo serviço de apoio domiciliário, que reunirá os esforços de todos os especialistas, técnicos e auxiliares de saúde e bem-estar para chegar ainda mais perto dos seus clientes, com serviços de várias áreas, de modo permanente e no domicílio dos clientes, na região de Lisboa.</p>
                        </div>
                    </div>
                </div>


                <div className="hero-economia-right">
                    <img src={img3} alt="" className="small-img"/>
                    <div className="hero-economia-content container--x">
                        <div className="content-title">
                            <h2>Centro Dia</h2>
                        </div>
                        <div className="content-description">
                            <p className="small-width">A greatcare em breve irá lançar um novo serviço de centro dia, que reunirá também os esforços de todos os especialistas, técnicos e auxiliares de saúde e bem-estar para acolher mais clientes nos seus espaços e alargar assim a oferta para a população sénior e/ou com necessidades especiais, na região de Lisboa.</p>
                        </div>
                    </div>
                </div>


                <div className="hero-economia-left">
                    <img src={img4} alt="" />
                    <div className="hero-economia-content container--x">
                        <div className="content-title">
                            <h2>Consultoria e Serviço a Famílias</h2>
                        </div>
                        <div className="content-description">
                            <p>Ao longo do ano de 2022, a greatcare pretende desenvolver um novo projeto de consultadoria e serviços a Famílias e Organizações do Sector Social. As áreas de intervenção e os serviços que este projeto vai disponibilizar irão colmatar muitos dos problemas existentes atualmente, quer ao nível das Famílias quer ao nível das Organizações deste sector, contudo, o projeto é sustentado pelo conhecimento e know-how da equipa que apresenta mais de 20 anos de uma extensa e relevante experiência e prática de trabalho de campo com idosos institucionalizados, Famílias, gestão de unidades sociais e de prestação de cuidados de saúde e bem-estar. Este projeto pretende ajudar as Famílias a encontrar respostas às suas necessidades na área dos cuidados de saúde e bem-estar e auxiliar as Instituições na sua organização, gestão do dia-a-dia e cumprimento dos requisitos da legislação em vigor.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
