import React from 'react';
// Components
import Main from '../components/OrganizacaoDeEventos/Main';
import Hero from '../components/OrganizacaoDeEventos/Hero';
import ContactForm from '../components/ContactForm';

const OrganizacaoDeEventos = () => {
    return (
        <>
            <Main />
            <Hero />
            <ContactForm />
        </>
    )
}

export default OrganizacaoDeEventos
