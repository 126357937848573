import React from 'react';
// Images
import img1 from '../../imgs/dispositivosMedicos/img-1.jpg';
import img2 from '../../imgs/dispositivosMedicos/img-2.jpg';
import logo1 from '../../imgs/dispositivosMedicos/logo-1.png';
import logo2 from '../../imgs/dispositivosMedicos/logo-2.png';
// Icons
import { BsDot } from 'react-icons/bs';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from 'react-icons/ai';

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero__wrapper__cuidados">
                <div className="hero__wrapper__right">
                    <img src={img1} alt="Image" className="image-hero"/>
                    <div className="hero__wrapper__content container--x">
                        <img className="logos" src={logo1} alt="Logo" />
                        <h3>Soluções:</h3>
                        <br />
                        <ul><BsDot /> Suportes e ortóteses​</ul>
                        <ul><BsDot /> Terapia compressiva</ul>
                        <ul className="dot-left"><BsDot /> Meias de descanso</ul>
                        <ul className="dot-left"><BsDot /> Meias, mangas e luvas de compressão</ul>
                        <ul className="dot-left"><BsDot /> Acessórios</ul>
                        <ul><BsDot /> Palmilhas</ul>
                        <ul><BsDot /> Tecnologia de medição</ul>
                        <hr />
                        <p>Tel: 21 382 69 65</p>
                        <div className="email-website">
                        <p>E-mail: info@bauerfeind.com.pt</p>
                        <p>Loja: Rua Manuel da Silva Leal, nº6C, 1600-166 Lisboa</p>
                        <p>Loja Online: www.bauerfeind.store</p>
                        </div>
                        <p>Website internacional: www.bauerfeind.com</p> 
                        <div className="hero-social-media">
                            <AiFillFacebook />
                            <AiFillInstagram />
                            <AiFillYoutube  />
                        </div>
                    </div>
                </div>
                <div className="hero__wrapper__left">
                    <img src={img2} alt="Image" />
                    <div className="hero__wrapper__content container--x">
                        <img className="logos" src={logo2} alt="Logo" />
                        <h3>Soluções:</h3>
                        <br />
                        <ul><BsDot /> Calçado​</ul>
                        <ul className="dot-left"><BsDot /> Calçado de conforto</ul>
                        <ul className="dot-left"><BsDot /> Calçado ortopédico</ul>
                        <ul className="dot-left"><BsDot /> Calçado pós-cirúrgico</ul>
                        <ul><BsDot /> Soluções de conforto para o pé</ul>
                        <hr />
                        <p>Tel: 21 382 69 65</p>
                        <div className="email-website">
                        <p>Email: info@berkemann.pt</p>
                        <p>Loja: Rua Manuel da Silva Leal, n.º 6 C, 1600-166 Lisboa</p>
                        <p>website internacional: www.berkemann.com/en</p>
                        </div>
                        <p>Website internacional: www.bauerfeind.com</p> 
                        <div className="hero-social-media">
                            <AiFillFacebook />
                            <AiFillInstagram />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
