import React from 'react';
// Components
import Hero from '../components/CuidadosDeSaude/Hero'
import Slider from '../components/CuidadosDeSaude/Slider';
import ContactForm from '../components/ContactForm';
import Parcerias from '../components/CuidadosDeSaude/Parcerias';

const CuidadosDeSaude = () => {
    return (
        <>
           <Hero /> 
           <Slider />
           <Parcerias />
           <ContactForm />
        </>
    )
}

export default CuidadosDeSaude
