import React from 'react';
// React Router
import { Switch, Route } from 'react-router-dom';
// Styles
import './assets/styles/main.scss';
// Components
import Header from './components/Header';
import Footer from './components/Footer';
// Pages
import Home from './pages/Home';
import About from './pages/About';
import EconomiaSocial from './pages/EconomiaSocial';
import CuidadosDeSaude from './pages/CuidadosDeSaude';
import DispositivosMedicos from './pages/DispositivosMedicos';
import OrganizacaoDeEventos from './pages/OrganizacaoDeEventos';
import Contact from './pages/Contact';

function App() {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/sobre" exact>
          <About />
        </Route>
        <Route path="/EconomiaSocial" exact>
          <EconomiaSocial />
        </Route>
        <Route path="/CuidadosDeSaude" exact>
          <CuidadosDeSaude />
        </Route>
        <Route path="/DispositivosMedicos" exact>
          <DispositivosMedicos />
        </Route>
        <Route path="/OrganizacaoDeEventos" exact>
          <OrganizacaoDeEventos />
        </Route>
        <Route path="/Contacto" exact>
          <Contact />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
