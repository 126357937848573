import React from 'react';
// Components
import Hero from '../components/About/Hero';
import ContactForm from '../components/ContactForm';

const About = () => {
    return (
        <>
           <Hero />
           <ContactForm />
        </>
    )
}

export default About
