import React from 'react';
// Images
import logo1 from '../../imgs/cuidadosSaude/logo1.png';
import logo2 from '../../imgs/cuidadosSaude/logo2.png';

const Parcerias = () => {
    return (
        <div className="parcerias">
            <div className="parcerias__wrapper container container--x">
                <div className="parcerias__text">
                    <p>1) Parceria estabelecida com JCS (Joaquim Chaves Saúde)</p>
                    <p>2) Parceria estabelecida com NRD (Núcleo Rádio Diagnóstico)</p>
                </div>
                <img src={logo1} alt="Joaquim Chaves" />
                <img src={logo2} alt="NRD" />
            </div>
        </div>
    )
}

export default Parcerias;
