import React from 'react'
import { aboutData } from '../../assets/data/aboutData'

const Hero = () => {
    return (
        <div className="about">
            <div className="about__wrapper container container--x">
                <div className="about__top">
                <h1>Quem Somos</h1>
                <p>A greatcare é uma PME sediada na área de Lisboa desde 2004, com a experiência de mais de uma década e meia na prestação de cuidados de saúde e bem-estar. Possui diversas áreas de negócio e uma diversidade de serviços e soluções especializadas. A greatcare, dispõe de uma equipa multidisciplinar de técnicos e especialistas altamente qualificados, com larga experiência e competência nas áreas de negócio que desenvolve, orientados para a relação com o Cliente que, habitualmente, aconselha e referencia os seus serviços e soluções.</p>
                </div>
                <div className="about__grid">
                    {aboutData.map((item, index) => (
                        <div className="box" key={index}>
                            <img src={item.image} alt="" />
                            <h2>{item.title}</h2>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
                <div className="about__span">
                    <h1>Mais do que lidar com clientes, lidamos com pessoas.</h1>
                </div>
            </div>
        </div>
    )
}

export default Hero
