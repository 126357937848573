import React from 'react';
// Images
import img2 from '../../imgs/organizacaoDeEventos/img2.jpg';
import img3 from '../../imgs/organizacaoDeEventos/img3.jpeg';

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero__wrapper__organizacao">
                <div className="hero__wrapper__org__right ">
                    <img src={img2} alt="" />
                    <div className="hero__org__content container--x">
                    <h1>Organização de eventos</h1>
                    <p>O nosso serviço de organização de eventos caracteriza-se pela multiplicidade de soluções, sempre ao encontro da sua vontade. Diga-nos o local ou deixe-nos sugerir-lhe um e nós transformamos o seu desejo num evento de sucesso!
                    Determinação, originalidade, profissionalismo e qualidade, são algumas das características que vão fazer parte dos seus eventos.</p>
                    <div className="list">
                        <div className="list-1">
                            <h4>Eventos para Empresas</h4>
                            <ul>Apresentações;</ul>
                            <ul>Inaugurações;</ul>
                            <ul>Recepções;</ul>
                            <ul>Congressos;</ul>
                            <ul>Reuniões;</ul>
                            <ul>Seminários;</ul>
                            <ul>Workshops;</ul>
                            <ul>Lançamentos;</ul>
                            <ul>Confraternizações;;</ul>
                            <ul>Aniversários;</ul>
                            <ul>Outros.</ul>
                        </div>
                        <div className="list-2">
                            <h4>Eventos para Particulares</h4>
                            <ul>Casamentos;</ul>
                            <ul>Baptizados;</ul>
                            <ul>Aniversários;</ul>
                            <ul>Festas Temáticas;</ul>
                            <ul>Outros.</ul>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="hero__wrapper__org__left">
                <img src={img3} alt="" />
                    <div className="hero__org__content container--x">
                    <h1>Serviços catering</h1>
                    <p>Realize o evento da sua Empresa, no seu espaço ou num espaço sugerido por nós, contacte-nos e nós concretizamos a sua ideia.</p>
                    <div className="list">
                        <div className="list-1">
                            <h4>Eventos de Catering:</h4>
                            <ul>Inaugurações;</ul>
                            <ul>Apresentações;</ul>
                            <ul>Recepções;</ul>
                            <ul>Reuniões;</ul>
                            <ul>Seminários;</ul>
                            <ul>Congressos;</ul>
                        </div>
                        <div className="list-2">
                            <ul>Pequenos-almoços;</ul>
                            <ul>Brunchs;</ul>
                            <ul>Almoços;</ul>
                            <ul>Cocktails;</ul>
                            <ul>Jantares;</ul>
                            <ul>Outros.</ul>
                        </div>
                    </div>
                    <p>Pode também contar com o nosso Corporate Catering no dia-a-dia da sua Empresa e dos seus colaboradores.</p>
                    <p>Dispomos de um serviço de delivery diário de salgados, doces ou mesmo de refeições prontas (sujeito a disponibilidade e marcação prévia).</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
