import React from 'react';

const Info = () => {
    return (
        <section className="info-dispositivos">
            <div className="info-dispositivos__wrapper container container--x">
                <p>A greatcare dispõe desde 2020 uma resposta ao nível dos Dispositivos médicos, com o objetivo de oferecer um portefólio abrangente nas áreas da Medicina Desportiva, Ortopedia, Neurocirurgia, Cirurgia Vascular e Medicina Física e de Reabilitação e se tornar uma referência nesta área.</p>
                <br />
                <p>A greatcare é o representante e distribuidor exclusivo das soluções do grupo Bauerfeind em Portugal (Continente e Ilhas).</p>
                <br />
                <p>As soluções do grupo Bauerfeind, que englobam as marcas Bauerfeind e Berkemann, são líderes mundiais no fabrico de soluções ortopédicas, marcas premium em suportes e ortóteses, terapia compressiva, palmilhas, tecnologias de medição, calçado ortopédico e soluções de conforto para o pé / soluções para podologia.</p>
                <br />
                <p>As marcas, de origem alemã, contam com mais de 90 anos de experiência nas áreas de medicina desportiva, ortopedia, reabilitação e terapia compressiva (desde o ano 1929) e mais de 130 anos no fabrico de calçado ortopédico (desde o ano 1885). São consideradas marcas de renome mundial, devido à sua superior qualidade, à tecnologia inovadora utilizada e à forma como contribuem significativamente com os seus produtos e conceitos de tratamento para manter e restabelecer a saúde e bem-estar, com vista a uma melhoria da qualidade de vida dos clientes.</p>
                <br />
                <p>As suas soluções são fabricadas através da colaboração direta entre médicos, cientistas e especialistas de diversas áreas, cujo resultado se traduz em produtos e equipamentos de alta tecnologia, qualidade e fiabilidade, dando origem a soluções patenteadas e galardoadas com vários prémios.</p>
            </div>
        </section>
    )
}

export default Info
