import React from 'react'
import Hero from '../components/EconomiaSocial/Hero'
import ContactForm from '../components/ContactForm'

const EconomiaSocial = () => {
    return (
        <>
           <Hero /> 
           <ContactForm />
        </>
    )
}

export default EconomiaSocial
