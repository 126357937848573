import React, { useState } from 'react';
// React Icons
import { HiMenu } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
// React Router
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [menu, setMenu] = useState(false);
    const menuHandler = () => {
        setMenu(!menu);
    }

    const closeMobileMenu = () => {
        setMenu(false);
    }

    return (
        <header className="header">
            <div className={menu ? 'overlay' : ''}></div>
                <nav className="nav container container--x flex flex-jc-sb flex-ai-c">
                    <div className="nav__logo">
                        <Link to="/"><h3>great<span>care</span>.</h3></Link>
                    </div>

                    <div className="nav__icon hide-for-desktop" onClick={menuHandler}>
                        {menu ? <IoClose /> : <HiMenu />}
                    </div>

                    <ul className={menu ? 'nav__menu active' : 'nav__menu flex hide-for-mobile'}>
                       <li>
                           <NavLink exact to="/Sobre" activeClassName="nav-active" onClick={closeMobileMenu}>Quem Somos</NavLink>
                       </li>
                       <li>
                           <NavLink exact to="/EconomiaSocial" activeClassName="nav-active" onClick={closeMobileMenu}>Economia Social</NavLink>
                       </li>
                       <li>
                           <NavLink exact to="/CuidadosDeSaude" activeClassName="nav-active" onClick={closeMobileMenu}>Cuidados de Saúde</NavLink>
                       </li>
                       <li>
                           <NavLink exact to="/DispositivosMedicos" activeClassName="nav-active" onClick={closeMobileMenu}>Dispositivos Médicos</NavLink>
                       </li>
                       <li>
                           <NavLink exact to="/OrganizacaoDeEventos" activeClassName="nav-active" onClick={closeMobileMenu}>Organização de Eventos</NavLink>
                       </li>
                       <li>
                           <NavLink exact to="/Contacto" activeClassName="nav-active" onClick={closeMobileMenu}>Contactos</NavLink>
                       </li>
                    </ul>
                </nav>
        </header>
    )
}

export default Header
