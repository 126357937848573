import React from 'react';
// Images
import logo from '../../imgs/organizacaoDeEventos/logo.jpg';

const Main = () => {
    return (
        <section className="main">
            <div className="main__wrapper container container--x">
                <img src={logo} alt="Coffe Girls" />
                <div className="content">
                    <div className="content__top">
                        <h2>Quem Somos</h2>
                        <p>O conceito Coffeegirls® foi criado em Lisboa, em 2009, para apresentar um serviço personalizado e diferenciado, com qualidade e excelência, na organização de eventos e serviço de catering, focado nas necessidades do cliente. 
                        Inicialmente direccionado à indústria farmacêutica, alargou-se rapidamente a outros ramos empresariais e privados na região da grande Lisboa. Constituído por pessoas, inspiradas nas suas experiências individuais e no quotidiano que as envolve, sempre movidas pela paixão de fazer melhor e diferente.</p>
                    </div>

                    <hr />
                    <div className="content__info">
                        <p>Tel: 21 382 69 65</p>
                        <div className="email-website">
                        <p>Email: info@coffeegirls.pt</p>
                        <p>Website: www.coffeegirls.pt</p>
                        </div>
                        <p>Coffeegirls® é uma marca registada da greatcare</p> 
                    </div>            
                </div>
            </div>
        </section>
    )
}

export default Main
