import React from 'react';
// Components
import Info from '../components/DispositivosMedicos/Info';
import Hero from '../components/DispositivosMedicos/Hero';
import ContactForm from '../components/ContactForm';

const DispositivosMedicos = () => {
    return (
        <>
            <Info />
            <Hero />
            <ContactForm />
        </>
    )
}

export default DispositivosMedicos
