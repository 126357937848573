import missao from '../../imgs/about/missao.jpg';
import visao from '../../imgs/about/visao.jpg';
import valores from '../../imgs/about/valores.jpg';

export const aboutData = [
    {
        image: missao,
        title: 'Missão',
        text: 'Fornecer soluções e serviços, especializados e de qualidade, contribuindo para a melhoria da qualidade de vida dos clientes.',
    },
    {
        image: visao,
        title: 'Visão',
        text: 'Pretendemos ser uma organização de referência, nas áreas em que atuamos.',
    },
    {
        image: valores,
        title: 'Valores',
        text: 'Orientação para os resultados e para os clientes;   Planeamento e gestão por objetivos; Orientação pela melhoria contínua; Integridade, ética e profissionalismo.',
    }
]