import React from 'react';
// Images
import img1 from '../../imgs/cuidadosSaude/img1.jpg';
import img2 from '../../imgs/cuidadosSaude/img2.jpg';
import img3 from '../../imgs/cuidadosSaude/img3.jpg';
import img4 from '../../imgs/cuidadosSaude/img4.jpeg';
import img5 from '../../imgs/cuidadosSaude/img5.jpg';
import img6 from '../../imgs/cuidadosSaude/img6.jpg';
import img7 from '../../imgs/cuidadosSaude/img7.jpg';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"


// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

const Slider = () => {
    return (
        <div className="swiper-2">
            <Swiper slidesPerView={1} spaceBetween={10} pagination={{
            "clickable": true
            }} breakpoints={{
            "640": {
            "slidesPerView": 1,
            "spaceBetween": 10
            },
            "768": {
            "slidesPerView": 2,
            "spaceBetween": 10
            },
            "1024": {
            "slidesPerView": 4,
            "spaceBetween": 10
            }
            }} className="mySwiper">
            <SwiperSlide><img src={img1} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img2} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img3} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img4} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img5} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img6} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img7} alt="" /></SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Slider
