import React from 'react';
// Images
import bg1 from '../../imgs/home/bg-1.jpg';
import bg2 from '../../imgs/home/bg-2.jpg';
import bg3 from '../../imgs/home/bg-3.jpg';
import bg4 from '../../imgs/home/bg-4.jpg';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// React Router   
import { Link } from 'react-router-dom';
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, {
    Navigation,Pagination,Mousewheel,Keyboard
  } from 'swiper/core';
  // install Swiper modules
  SwiperCore.use([Navigation,Pagination,Mousewheel,Keyboard]);


const Hero = () => {
    return (
        <section className="hero">
            <Swiper cssMode={true} navigation={true} pagination={true} mousewheel={true} keyboard={true} className="mySwiper">
                <SwiperSlide><img src={bg1} alt="" />
                <div className="swiper-content container--x">
                    <h1>Economia Social</h1>
                    <Link className="btn-white" to="/EconomiaSocial">SABER MAIS</Link>
                </div>
                </SwiperSlide>
                <SwiperSlide><img src={bg2} alt="" />
                <div className="swiper-content container--x">
                    <h1>Cuidados de Saúde</h1>
                    <Link className="btn-white" to="/CuidadosDeSaude">SABER MAIS</Link>
                </div>
                </SwiperSlide>
                <SwiperSlide><img src={bg3} alt="" />
                <div className="swiper-content container--x">
                    <h1>Dispositivos médicos</h1>
                    <Link className="btn-white" to="/DispositivosMedicos">SABER MAIS</Link>      
                </div>
                </SwiperSlide>
                <SwiperSlide><img src={bg4} alt="" />
                <div className="swiper-content container--x">
                    <h1>Organização de eventos</h1>
                    <Link className="btn-white" to="/OrganizacaoDeEventos">SABER MAIS</Link>
                </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Hero
