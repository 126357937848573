import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__wrapper container container--x">
                <p>© 2021 greatcare, business & services lda.</p>
                <p>Proteção de dados</p>
                <p>Avisos legais</p>
            </div>
        </footer>
    )
}

export default Footer
