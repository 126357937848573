import React from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const ContactForm = () => {

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('gmailMessage', 'template_5wzu2mc', e.target, 'user_8xpQRubKUxrlW3m7QaXfm')
          .then((result) => {
              alert('Mensagem Enviada com Sucesso');
          }, (error) => {
              alert(error.message)
          });
          e.target.reset();
    }

    return (
        <section className="contact__form">
            <h3 className="container--x">CONTACTOS</h3>
            <div className="contact__form__wrapper container--x container">
                <div className="contact__info">
                    <div className="contact__info__text">
                        <p><span className="bold">greatcare, business & services lda.</span></p>
                        <p>M.C.R.C. Lisboa e Pessoa Colectiva nº PT 506881164</p>
                    </div>
                    <div className="contact__info__text">
                        <p><span className="bold">Sede:</span> Rua dos Faisões, Vila Bicuda, 153 - 2750-689 Cascais</p>
                        <p><span className="bold">Loja:</span> Rua Manuel da Silva Leal, 6 C, 1600-166 Lisboa</p>
                    </div>
                    <div className="contact__info__text">
                        <p><span className="bold">email:</span>info@greatcare.pt</p>
                        <p><span className="bold">Telef.:</span> 21 198 9662</p>
                        <p><span className="bold">Fax:</span> 21 382 6969</p>
                    </div>
                    <div className="contact__info__text">
                        <p>www.greatcare.pt</p>
                    </div>
                </div>

                <form action="" className="contact__wrapper__form" onSubmit={sendEmail}>
                    <input type="text" placeholder="Nome" required name="name"/>
                    <input type="email" placeholder="Email" required name="email"/>
                    <input type="tel" placeholder="Telefone" required name="phone"/>
                    <textarea name="" id="" cols="30" rows="10" placeholder="Digite a sua mensagem aqui..." required name="message"></textarea>
                    <div className="form__cta">
                        <input type="submit" className="btn-black" value="Enviar Mensagem"/>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default ContactForm
