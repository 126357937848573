import React from 'react';
// Components
import Hero from '../components/Home/Hero';
import ContactForm from '../components/ContactForm';

const Home = () => {
    return (
        <>
            <Hero />
            <ContactForm />
        </>
    )
}

export default Home
